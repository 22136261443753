.tag {
    background: rgba(0,0,0,.35);
    bottom: 0px;
    color: white;
    position: absolute;
}

.heading {
    color: inherit;
    font-size: 16rem;
    margin: 5px 10px;
    text-transform: capitalize;
}

.extra {
    color: inherit;
    display: flex;
    margin-bottom: -14rem;
}

.price {
    background: var(--teal);
    color: inherit;
    font-family: Bebas Neue,sans-serif;
    font-size: 21rem;
    font-weight: 700;
    padding: 7px 10px 3px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-transform: uppercase;
}

.info {
    background: white;
    border: 1px solid var(--teal);
    font-size: 14rem;
    font-weight: 700;
    padding: 7px 10px 3px;
}