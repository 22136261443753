.outer_wrapper {
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
}

.wrapper {
    animation: fadeIn .3s ease-out;
    background-color: var(--input-border-c);
    border: 1px solid var(--input-border-c);
    border-top: none;
    display: grid;
    grid-gap: 1px;
}

.pane {
    background-color: white;
    color: black;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 88px;
    padding: 0 26px;
}

.pane_action {
    align-items: center;
    color: inherit;
    display: flex;
    user-select: none;
    width: 100%;
}

.pane_action > i {
    margin-left: auto;
}

.pane_content {
    animation: fadeIn .3s ease-out;
    background-color: white;
    display: grid;
    grid-gap: 14px;
    grid-column: 1 / -1;
    padding: 26px;
}

.pane_content h3,
.pane_content label,
.pane_content input {
    color: black;
}

.pane_content h3 {
    font-size: 14rem;
}

.other_actions {
    background-color: white;
    border: 1px solid var(--input-border-c);
    border-top: none;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.other_actions button {
    height: 56px;
}

.standalone_pane {
    align-items: center;
    grid-column: 1 / -1;
}

.standalone_pane > .pane_content {
    align-items: center;
    display: grid;
    grid-gap: 30px;
}

@media screen and (min-width: 481px) {
    .other_actions {
        flex-direction: row;
    }
}

@media screen and (min-width: 1151px) {
    .wrapper,
    .pane_content {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .wrapper {
        border-top: 1px solid var(--input-border-c);
    }

    .standalone_pane > .pane_content {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@keyframes fadeIn {
    from { opacity: .5; }
    to { opacity: 1; }
}