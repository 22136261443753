.pseudo_label {
    color: inherit;
    font-size: 12rem;
    margin: 0 4px;
}

.unit_wrapper {
    position: relative;
}

.unit {
    color: black;
    font-size: 12rem;
    position: absolute;
    right: 4px;
    top: 0;
}