.complex {
    display: flex;
    font-weight: bold;
    gap: 5px;
}

.complex [data-label] {
    color: #000;
}

.two-inline input {
    margin-top: 3px;
}

.two-inline [data-label]:first-child {
    flex: 1 1 auto;
}

.two-inline [data-label]:last-child {
    font-size: 13rem;
    flex: 0 0 96px;
}