.position_wrapper {
    position: relative;
}

.dropdown {
    animation: appear .3s ease-out;
    border: 1px solid var(--input-border-c);
    border-top: none;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;
}

@keyframes appear {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0px); }
}