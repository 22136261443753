.links {
    background-color: white;
    display: flex;
    grid-column: 1 / -1;
    justify-content: space-between;
    overflow-x: auto;
    padding: 20px 26px;
    width: 100%;
}

.link {
    cursor: pointer;
    display: block;
    font-size: 14rem;
    white-space: nowrap;
    width: max-content;
}

.link:not(:last-child) {
    margin-right: 32px;
}

.active {
    color: var(--teal);
}