.section {
    background-color: var(--input-border-c);
    width: 100%;
    position: relative;
    z-index: 6;
}

.primary {
    z-index: 7;
}

.image {
    animation: fadeIn 1.4s ease-out normal forwards;
    display: none;
    height: 100%;
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: -1;
}

/* "Collapse when" rules */

.never .image {
    display: block;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@media screen and (max-width: 1150px) {
    .form_expands {
        height: auto !important;
    }
}

@media screen and (min-width: 1151px) {
    .form_expands .image {
        display: block;
    }
}
