.active_link,
.link {
    align-items: center;
    color: black;
    display: flex;
    font-weight: bold;
    height: 47px;
    padding: 0 10px;
    width: 100%;
}

.active_link > img,
.link > img {
    margin-right: 5px;
}

.active_link > img {
    filter: invert(18%) sepia(61%) saturate(3269%) hue-rotate(347deg) brightness(85%) contrast(101%);
}

.active_link {
    background: white;
    box-shadow: 0 4px 4px -2px #999;
    transform: scale(1.05);
}

.link {
    background: var(--gray);
}

.buttonlink {
    border: none;
    cursor: pointer;
}

.buttonlink i {
    font-size: 18rem;
}

@media screen and (min-width: 1151px) {
    .active_link {
        box-shadow: none;
        transform: none;
    }
}