.input,
.label {
    cursor: pointer;
}

.input {
    appearance: none;
    background-color: var(--gray);
    border: 1px solid var(--input-border-c);
    border-radius: 5px;
    left: 0;
    margin: 0;
    padding: calc(var(--chbx-s) / 2);
    position: absolute;
}

.input:checked::after {
    --chbx-spacing: calc(var(--chbx-s) / 4);
    background-color: var(--teal);
    border-radius: 3px;
    content: '';
    left: var(--chbx-spacing);
    padding: var(--chbx-spacing);
    position: absolute;
    top: var(--chbx-spacing);
}

.label {
    display: inline-block;
    min-height: var(--chbx-s);
    position: relative;
}

.span {
    color: inherit;
    display: inline-block;
    margin-bottom: -2px;
    margin-right: 10px;
    padding-left: calc(var(--chbx-s) + 8px);
    vertical-align: middle;
}

.span[required]::after {
    color: var(--red);
    content: '*';
    font-size: 15rem;
    margin-left: 6px;
}