.wrapper {
    width: 100%;
    position: relative;
}

.hints {
    animation: maxHeight300 .3s ease-out normal forwards;
    background-color: white;
    border: 1px solid var(--input-border-c);
    border-top: none;
    color: black;
    line-height: 1.3;
    list-style: none;
    margin: 0;
    overflow-y: scroll;
    padding: 5px 10px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 5;
}

.active-hint,
.hint {
    color: inherit;
    cursor: pointer;
    font-weight: bold;
    opacity: .6;
    user-select: none;
}

.active-hint,
.hint:hover {
    opacity: 1;
}

.active-hint {
    background: var(--dark-blue);
    color: #fff;
    margin: 0 -10px 0 -10px;
    padding: 0 10px 0 10px;
}

@keyframes maxHeight300 {
    from { max-height: 0; opacity: .2; }
    to { max-height: 300px; opacity: 1; }
}