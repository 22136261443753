.wrapper {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    height: 100%;

    --banner-s: 400px;
}

.search {
    background: white;
    width: 100%;
    z-index: 10;
}

.formpicker {
    border-top: 1px solid var(--input-border-c);
    display: flex;
    flex: 0 0 var(--car-search-link-w);
    max-width: 100%;
    overflow-x: auto;
    padding: 10px 0;
    position: relative;
}

.formpicker::after {
    background: white;
    color: black;
    content: 'chevron_right';
    font-family: Material Icons;
    font-size: 20rem;
    padding-top: 13px;
    position: sticky;
    right: 0;
}

.search_form {
    background-color: var(--input-border-c);
    border-left: 1px solid var(--input-border-c);
    height: 100%;
    width: 100%;
}

.search_form input:not(:placeholder-shown):not([type='checkbox']) {
    border-color: var(--teal);
}

@media screen and (min-width: 1151px) {
    .wrapper {
        gap: 26px;
        margin: auto;
        max-width: var(--car-search-w);
        padding: 13px;
    }

    .wrapper.with_banner .search {
        height: var(--banner-s);
    }

    .wrapper.with_banner .formpicker {
        background: var(--gray);
    }

    .wrapper.with_banner .formpicker > * {
        align-items: center;
        display: flex;
        flex-grow: 1;
    }

    .wrapper.with_banner .search_form {
        background-color: #fff;
        display: grid;
        grid-gap: 1px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .wrapper.with_banner [for="kraj-pochodzenia"] {
        font-size: 12px;
    }

    .wrapper.with_banner [data-all-options] {
        color: #000;
        text-align: right;
    }

    .search_form {
        display: grid;
        grid-gap: 1px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .search_input_wrapper {
        padding: 0 16px;
        border-top: none;
    }

    .banner {
        background: #fff;
        height: var(--banner-s);
        min-width: var(--banner-s);
        width: var(--banner-s);
    }

    .search_links_on_top,
    .search {
        display: flex;
        left: 50%;
        max-width: 100%;

        width: var(--car-search-w);
        flex-grow: 1;
    }

    .search_links_on_top {
        border-bottom: 1px solid var(--input-border-c);
        bottom: 0;
        flex-wrap: wrap;
    }

    .search {
        bottom: 30px;
    }

    .formpicker {
        flex-direction: column;
        overflow: visible;
        padding: 0;
    }

    .formpicker::after {
        display: none;
    }

    .search_links_on_top > .formpicker {
        border: 1px solid var(--input-border-c);
        flex: 0 0 100%;
        flex-direction: row;
    }

    .search_links_on_top > .formpicker > a {
        flex-grow: 1;
        flex-shrink: 1;
    }
}