.label {
    color: inherit;
    cursor: pointer;
}

.label:not(:last-child) > .label_text {
    margin-right: 16px;
}

.radio {
    cursor: pointer;
    margin-right: 10px;
}

.label_text {
    color: inherit;
    display: inline-block;
    max-width: calc(100% - 45px);
    vertical-align: middle;
}