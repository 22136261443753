.search_form {
    background-color: var(--input-border-c);
    border-left: 1px solid var(--input-border-c);
    height: 100%;
    width: 100%;
}

.search_form input:not(:placeholder-shown):not([type='checkbox']) {
    border-color: var(--teal);
}

@media screen and (min-width: 1151px) {
    .search_form {
        display: grid;
        grid-gap: 1px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .search_input_wrapper {
        padding: 0 16px;
        border-top: none;
    }
}