.wrapper {
    display: block;
    width: 100%;
}

.wrapper > label {
    margin-bottom: 10px;
}

.wrapper_vertical {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(244px, 1fr));
    height: max-content;
}

.wrapper > h3,
.wrapper_vertical > h3 {
    margin-left: 4px;
}


.wrapper_vertical > h3 {
    grid-column: 1 / -1;
    margin-top: 0;
}

.wrapper_vertical > label {
    margin-bottom: 10px;
}