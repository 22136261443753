.wrapper {
    background-color: white;
    padding: 10px 16px;
}

.wrapper > [data-label] {
    align-items: center;
    color: black;
    display: flex;
    font-weight: bold;
    height: 100%;
    max-width: 100%;
    width: 100%;
}