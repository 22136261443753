.action_bar {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    height: 114px;
    position: relative;
}

.no_fuel {
    border-top: 1px solid var(--input-border-c);
    height: 57px;
}

.fuels {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: space-between;
    padding-left: 16px;
    border-top: 1px solid var(--input-border-c);
}

.fuels img {
    display: none;
}

.mobile-fuels {
    padding: 10px 16px;
}

.mobile-fuels > [data-label] {
    align-items: center;
    color: #000;
    display: flex;
}

.actions {
    display: flex;
    justify-content: flex-end;
    color: inherit;
    height: 100%;
    width: 100%;
}

.button {
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    font-weight: bold;
    margin-right: 10px;
}

.button i {
    font-size: 18rem;
}

.fuels,
.actions {
    border-bottom: 1px solid var(--input-border-c);
}

@media screen and (min-width: 1151px) {
    .action_bar {
        align-items: center;
        flex-direction: row;
        grid-column: 1 / -1;
        height: auto;
        justify-content: space-between;
        min-height: 56px;
        padding-left: 16px;
    }

    .no_fuel {
        border: none;
    }

    .fuels {
        padding: 0;
    }

    .actions {
        margin-left: auto;
        width: auto;
    }

    .fuels,
    .actions {
        border: none;
    }
}

@media screen and (min-width: 630px) {
    .fuels {
        justify-content: flex-start;
    }

    .fuels img {
        display: flex;
    }
}