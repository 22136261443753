.fuel_button {
    align-items: center;
    background: transparent;
    border: none;
    color: black;
    cursor: pointer;
    display: flex;
    font-size: 12rem;
    font-weight: bold;
    padding: 0px 5px;
    margin-right: 2px;
}

.fuel_button img {
    margin-right: 6px;
    pointer-events: none;
}

.active {
    border: 1px solid #fff;
    background-color: var(--gray-1);
    border-radius: 25px;
}

.active img {
    filter: invert(50%) sepia(27%) saturate(5882%) hue-rotate(146deg) brightness(100%) contrast(101%);
}